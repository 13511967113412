button {
  background-color: #632D47;
  border: none;
  border-radius: 20px;
  font-size: 2.5vw;
  font-weight: 300;
  color: #E8C8CB;
  padding: 2vw 2.5vw;
  transition: 300ms ease-out;
  margin-left: -3vw;
  box-shadow: -5px 5px 8px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

button:hover {
  transform: scale(1.1);
  transition: 300ms ease-in;
}