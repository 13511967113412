.va-card-container {
  width: 37vw;
  margin-left: -1vw;
}

.va-card-container p {
  font-size: 2vw;
  font-weight: 300;
  color: #E8C8CB;
  line-height: 2.5vw;
  width: 15vw;
  text-align: center;
}

.va-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10vw;
}

.va-top img {
  width: 20vw;
  height: 20vw;
  filter: drop-shadow(-5px 5px 8px #000);
}

.va-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.va-bottom img {
  width: 20vw;
  height: 20vw;
  filter: drop-shadow(-5px 5px 8px #000);
}