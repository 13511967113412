.contact-page-container {
  background-color: #224048;
  padding: 0 8vw 0 8vw;
}

.contact-hero {
  display: flex;
  justify-content: center;
  align-items: end;
}

.con-ex-lrg-heading {
 margin-bottom: 3vw;
}

.contact-hero img {
  width: 50vw;
  margin-bottom: -29.4vw;
  transform: rotate(-1.8deg)
}

.contact-hero-banner {
  width: 85vw;
  height: 30vw;
  background-color: #632D47;
  border-radius: 10px;
  box-shadow: -5px 5px 8px rgba(0, 0, 0, 0.5);
}

.contact-banner-text {
  font-size: 5vw;
  color: #E8C8CB;
  width: 40vw;
  padding-top: 10vw;
  padding-left: 5vw;
}

.contact-form {
  margin: 20vh 0 0 0;
}

.input {
  margin: 0 0 2vh -1vw;
}

.submit textarea{
  text-align: center;
  width: 18vw;
  height: 6vw;
  padding: 1.4vw 0 0 0;
  color: #632D47;
  font-size: 4vw;
  transition: 300ms ease-out;
  margin: 0 0 10vh 35vw;
  box-shadow: -5px 5px 8px rgba(0, 0, 0, 0.5);
}

.submit textarea:hover {
  transform: scale(1.1);
  transition: 300ms ease-in;
}

.con-sbl {
  margin: 0 0 10vh 21.8vw;
}

.small-contact-cards {
  display: flex;
  justify-content: space-between;
  margin: 0 0 2vh -15vw;
}

.email-card {
  margin: 0 2vw 0 8vw;
}

.phone-card {
  margin: 0 6vw 0 0;
}

.con-social-links {
width: 40vw;
margin: 0 0 0 27vw;
}

.con-social-links img{
  width: 6.67vw;
  height: 12.17vh;
  margin: 0 4vw 5vh 0
}

.con-footer {
  width: 2px;
  margin: 0 0 0 -10vw;
}