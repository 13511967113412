.about-page-container {
  background-color: #224048;
  overflow-x: hidden;
}
.vision-began-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 8vw 0 8vw;
  
}

.vb-header {
  grid-column: 1 / 3;
  padding: 10vh 0 5vh 0;
}

.vb-text-content {
  color: #E8C8CB;
  font-weight: 300;
  font-size: 1.9vw;
  line-height: 3vw;
  text-align: center;
  margin-top: 6vh;
  width: 48vw;
}

.vb-image img {
  width: 38vw;
  margin-left: 1vw;
  margin-top: 4vw;
}

.vb-button {
  grid-column: 1 / 3;
  padding: 0 0 10vh 35vw;
  margin-top: 4vw;
}

.vb-section-break-line {
  grid-column: 1 / 3;
  padding: 0 0 10vh 23vw;
}

.skills-envisioned-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 8vw 0 8vw;
}

.se-header {
grid-column: 1 / 3;
margin: 4vh 0 8vh 0;
}

.se-card-design {
  grid-column: 1 / 2;
  margin-bottom: 10vh;
}

.se-card-development {
  grid-column: 2 / 3;
}

.tools-container {
  grid-column: 1 / 3;
  margin: 0 0 10vh 15vw;
}

.tools-text {
  color: #34616D;
  font-size: 2.5vw;
}

.tools-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 65vw;
  height: 20vw;
  background:rgba(52, 97, 109, 0.3);
  border-radius: 1.25rem;
  margin-left: -5vw;
  box-shadow: -5px 5px 8px rgba(0, 0, 0, 0.5);
}

.tools-icon-container img {
  width: 4vw;
  height: 4vw;
  transition: 300ms ease-out;
}

.tools-icon-container img:hover {
  transform: scale(1.3);
  transition: 300ms ease-in;
  cursor: pointer;
  
}



.tic-top img {
  margin-right: 2rem;
}

.tic-bottom {
  margin-left: .5rem;
}

.tic-bottom img {
  margin-left: 2rem;
}

.se-button {
  grid-column: 1 / 3;
  margin: 0 0 10vh 33vw;
}

.se-section-break-line {
  grid-column: 1 / 3;
  margin: 0 0 10vh 21vw;
}

.evolution-vision-container {
  display: grid;
  padding: 0 8vw 0 8vw;
  grid-template-columns: 1fr 1fr;
  margin-top: -3vw;
}

.ev-header {
  grid-column: 1 / 3;
  width: 84vw;
  margin: 4vh 0 2vh -1.5vw;
}

.ev-image {
  grid-column: 1 / 2;
  margin-left: 12vw;
  width: 30vw;
}

.ev-image img {
  width: 75vw;
  margin-left: -27vw;
}

.ev-text-content {
  grid-column: 2 / 3;
  color:#E8C8CB;
  font-size: 1.9vw;
  line-height: 3vw;
  font-weight: 300;
  text-align: center;
  padding-top: 9vw;
  width: 40vw;
  margin-left: -4vw;
}

.ev-cards-container {
  grid-column: 1 / 3;
  display: flex;
  justify-content: space-between;
  width: 95vw;
  padding-right: 10vw;
  margin: -2vw 0 10vh -2vw;
}

.ev-button {
  grid-column: 1 / 3;
  margin: 0 0 10vh 31vw;
}

.ev-section-break-line {
  grid-column: 1 / 3;
  margin: 0 0 10vh 21vw;
}

.visualization-aside-container {
  display: grid;
  padding: 3vh 8vw 0 8vw;
  grid-template-columns: 1fr 1fr;
}

.va-header {
  grid-column: 1 / 3;
  margin: 0 12vw 0 0;
  padding-bottom: 8vh;
}

.va-cards-container {
  display: flex;
  justify-content: space-between;
  margin-left: -1vw
}

.vertical-section-break-line {
  width: 1.09vh;
  height: 35vh;
  background-color:#34616D;
  margin: 15vh 5vw 0 5vw;
}

.va-quote {
  font-size: 2.5vw;
  font-weight: 300;
  color: #34616D;
  grid-column: 1 / 3;
  margin: 8vh 0 8vh 0;
}

.va-quote-ref {
  padding: 2vh 0 0 47vw;
}