.small-contact-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #632D47;
  width: 48vw;
  height: 12vw;
  border-radius: 1.25rem;
  box-shadow: -5px 5px 8px rgba(0, 0, 0, 0.5);
}
.small-contact-card img {
  width: 9vw;
  height: 9vw;
}
.con-card-heading {
  font-size: 4vw;
  color: #E8C8CB;
}

.con-card-subheading {
  color: #E8C8CB;
  font-weight: 300;
}