.landing-page-container {
  width: 100%;
  height: 100vh;
  background: url('../Assets/Images/landing-bg-image.svg') no-repeat right bottom ;
  background-size: 70vw;
  background-color: #224048
}

.lp-social-links {
  margin-left: 55vw;
}

.lp-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: -10vh 0 0 -30vw;
}

.lp-content img {
  width: 35vw;
}

@media (min-width: 0px) and (max-width: 480px) {
  .landing-page-container {
    background-size: 160vw;
  }

  .lp-content img {
    width: 42vw;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .landing-page-container {
    background-size: 100vw;
  }
}
