.port-card-holder-container {
  padding: 0 8vw 0 8vw;
}

.pch-header {
  padding: 0 0 3vh 0;
}
.pch-para {
  font-size: 2.5vw;
  font-weight: 300;
  text-align: center;
  color: #E8C8CB;
  padding: 0 0 3vh 0;
}

.pch-card-container {
  transition: 300ms ease-out;
  
}

.pch-card-container:hover {
  transform: scale(1.2);
  transition: 300ms ease-in;
}

.pch-imgs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2vw;
}

.pch-imgs img {
  width: 41vw;
}

#pch-card-title {
  width: 41vw;
  position: absolute;
  margin-top: -23vw;
  text-align: center;
  color: #E8C8CB;
  font-weight: 300;
  font-size: 4.5vw;
}

#pch-card-subtitle {
  width: 41vw;
  position: absolute;
  margin-top: -16vw;
  text-align: center;
  color: #E8C8CB;
  font-weight: 300;
  font-size: 3.1vw;
}

#pch-card-item1 {
  width: 41vw;
  position: absolute;
  margin-top: -12vw;
  text-align: center;
  color: #E8C8CB;
  font-weight: 300;
  font-size: 2.1vw;
}

#pch-card-item2 {
  width: 41vw;
  position: absolute;
  margin-top: -10vw;
  text-align: center;
  color: #E8C8CB;
  font-weight: 300;
  font-size: 2.1vw;
}

#pch-card-item3 {
  width: 41vw;
  position: absolute;
  margin-top: -8vw;
  text-align: center;
  color: #E8C8CB;
  font-weight: 300;
  font-size: 2.1vw;
}

#pch-card-item4 {
  width: 41vw;
  position: absolute;
  margin-top: -6vw;
  text-align: center;
  color: #E8C8CB;
  font-weight: 300;
  font-size: 2.1vw;
}

