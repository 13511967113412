.ev-card-container {
  width: 27vw;
  height: 65vw;
}
.ev-card-title {
  color: #E8C8CB;
  font-size: 3.3vw;
  font-weight: 300;
  text-align: center;
}

.ev-card-content {
  min-height: 63vh;
  background-color: #34616D;
  border-radius: 1.25rem;
  font-size: 1.9vw;
  font-weight: 300;
  line-height: 3vw;
  color:#E8C8CB;
  text-align: center;
  padding: 3rem 1rem 1rem 1rem;
  box-shadow: -5px 5px 8px rgba(0, 0, 0, 0.5);
}

