.social-links-container {
  width: 28vw;
  top: 0;
  padding: 5vh 0 0 5vw;
}

.social-links-container ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style-type: none;
}

.social-links-container img {
  width: 4vw;
}

.social-links-container a img{
  cursor: pointer;
  transition: 300ms ease-out;
}

.social-links-container a:hover img {
 transform: scale(1.2);
 transition: 300ms ease-in;
}

@media screen and (max-width: 480px) {
  .social-links-container {
    width: 40vw;
  }

  .social-links-container img {
    width: 6vw;
  }
}