textarea {
  width: 85vw;
  background-color: #E8C8CB;
  border-radius: 1.25rem;
  border: none;
  resize: none;
  padding: 0.625rem 0 0 0.625rem;
  font-size: 4vw;
  font-weight: 300;
  box-shadow: -5px 5px 8px rgba(0, 0, 0, 0.5);
}