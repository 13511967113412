.footer {
  width: 33vw;
  justify-content: center;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  margin: 0 0 0 32vw;
}
.footer-top {
  display: flex;
  justify-content: space-around;
  align-items: center;
  grid-column: 1 / 3;
  margin-top: -4vw;
}

.footer-logo img {
  width: 16.5vw;
  transition: 300ms ease-out;
}

.footer-logo img:hover {
  transform: scale(1.1);
  transition: 300ms ease-in;
}


.footer-links li {
  list-style: none;
  color: #34616D;
  font-size: 2vw;
  margin-bottom: 1vw;
  transition: 300ms ease-out;
}

.footer-links li:hover {
  transform: scale(1.1);
  transition: 300ms ease-in;
  color: #78979F;
}

.footer-copyright {
  font-size: 1.5vw;
  grid-column: 1 / 3;
  color: #34616D;
  text-align: center;
}