.se-card-container {
  display: flex;
  justify-content: center;
  width: 40vw;
  height: 47vw;
  margin-top: 1vw;
  background-color: #E8C8CB;
  border-radius: 20px;
  box-shadow: -5px 5px 8px rgba(0, 0, 0, 0.5);
}

.se-card-content {
  width: 33vw;
  color: #632D47;
  text-align: center;
}

.se-card-header1 {
  font-size: 4vw;
  margin-top: 2rem;
}

.se-card-icon img{
  width: 11vw;
  margin-top: .5rem;
}

.se-card-header2 {
font-size: 3vw;
margin-top: 1.5vw;
}

.se-card-para {
  font-size: 2.3vw;
  font-weight: 300;
  margin-top: 1.8vw;
}

.se-card-header3 {
  font-size: 3vw;
  padding-top: 2.5vw;
}

.se-bullets {
  font-size: 2.3vw;
  font-weight: 300;
  text-align: start;
  padding-left: 12.5vw;
  margin-top: 1vw;
}